.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}

thead {
  position: sticky;
}

@media (max-width: 768px) {
  .flex-row {
    flex-direction: column;
    padding: 2em;
    align-items: center;
    gap: 1.5em;
  }

  .flex-col {
    padding: 1em;
    margin-top: 1.5em;
    gap: 1em;
  }

  .bg-gray-900 {
    width: 90%;
    max-width: 400px;
  }

  .justify-center {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    #line {
      height: 0.2px;
    }
  }
}
.footer {
  margin-top: auto;
  bottom: 0;
}
